import React from "react";
import css from './SectionValue.module.css';
import { FormattedMessage } from "react-intl";
import { Button, NamedLink, NamedLinkWithArrow } from "../../../components";

const SectionValue = props => {
    return (
        <div className={css.root}>
            <div className={css.backgroundImage}>
                <span className={css.title}>
                    <FormattedMessage
                        id="SectionValue.title"
                        values={{ lineBreak: <br /> }}
                    />
                </span>
                <span className={css.info}>
                    <FormattedMessage id="SectionValue.info" />
                </span>
                <NamedLinkWithArrow
                    className={css.button}
                    name="SignupPage"
                    content="SectionValue.ourFocus"
                />
            </div>
        </div>
    );
};

export default SectionValue;