import React from "react";
import css from './OtherListings.module.css'
import { NamedLink } from "../../components";
import { FormattedMessage } from "react-intl";
import ListingCardProduct from "../../components/ListingCardProduct/ListingCardProduct";

const OtherListings = props => {
    const { listings, currentUser } = props;

    return (
        <div className={css.root}>
            <div className={css.header}>
                <span className={css.title}>
                    <FormattedMessage id="OtherListings.newArrivals" />
                </span>

                <div className={css.linkWrapper}>
                    <NamedLink name="SearchPage" className={css.link}>
                        <FormattedMessage id="OtherListings.shopAll" />
                    </NamedLink>
                </div>
            </div>

            <div className={css.listingsContainer}>
                {listings?.map((listing, index) => (
                    <ListingCardProduct
                        className={css.listingCard}
                        key={index}
                        listing={listing}
                        currentUser={currentUser}
                    />
                ))}
            </div>

        </div>
    );
};

export default OtherListings;